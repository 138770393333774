import React from "react";

import ApiProvider from "./components/ApiProvider";
import { Api, ApiContext } from "./contexts/ApiContext";

export type GoogleAuthentication = Api;

export const GoogleAuthenticationProvider = ApiProvider;

export function useGoogleAuthentication(): GoogleAuthentication | undefined {
  return React.useContext(ApiContext);
}
