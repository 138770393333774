export const fromJson: (
  text: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reviver?: any
) => unknown = JSON.parse;

export const toJson: (
  value: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replacer?: any,
  space?: string | number
) => string = JSON.stringify;
