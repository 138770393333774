import React from "react";

import { ApiContext } from "../contexts/ApiContext";
import { CardanoWalletApi } from "../types";

export function useCardanoWalletApi(): CardanoWalletApi {
  const api = React.useContext(ApiContext);
  if (!api) {
    throw new Error("context not injected");
  }
  return api;
}
