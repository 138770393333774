import * as helios from "@hyperionbt/helios";
import { assert$DisplayableError } from "kreate-common/modules/displayable-error";

import { AddressBech32, WalletName } from "./types";

type InitialApi = {
  enable: () => Promise<helios.Cip30Handle>;
  isEnabled: () => Promise<boolean>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isInitialApi(obj: any): obj is InitialApi {
  return (
    typeof obj?.enable === "function" && typeof obj?.isEnabled === "function"
  );
}

// https://github.com/cardano-foundation/CIPs/tree/master/CIP-0030#apierror
export class ErrorCip30ApiError extends Error {}
export class ErrorCip30ApiError$InvalidRequest extends ErrorCip30ApiError {}
export class ErrorCip30ApiError$InternalError extends ErrorCip30ApiError {}
export class ErrorCip30ApiError$Refused extends ErrorCip30ApiError {}
export class ErrorCip30ApiError$AccountChange extends ErrorCip30ApiError {}

// https://github.com/cardano-foundation/CIPs/tree/master/CIP-0030#apierror
type Cip30ApiError = {
  code: number;
  info?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCip30ApiError(obj: any): obj is Cip30ApiError {
  return (
    typeof obj?.code === "number" &&
    ["undefined", "string"].includes(typeof obj?.info)
  );
}

export function isWalletInstalled(walletName: WalletName) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return isInitialApi((window as any)?.cardano?.[walletName]);
}

export async function enableWallet(
  walletName: WalletName
): Promise<helios.Cip30Handle> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialApi = (window as any)?.cardano?.[walletName];
  assert$DisplayableError(
    isInitialApi(initialApi),
    `Wallet not installed (${walletName})`
  );

  return await initialApi.enable().catch((error) => {
    if (isCip30ApiError(error)) {
      switch (error.code) {
        case -1:
          throw new ErrorCip30ApiError$InvalidRequest(error.info);
        case -2:
          throw new ErrorCip30ApiError$InternalError(error.info);
        case -3:
          throw new ErrorCip30ApiError$Refused(error.info);
        case -4:
          throw new ErrorCip30ApiError$AccountChange(error.info);
        default:
          throw new ErrorCip30ApiError(error.info);
      }
    } else {
      throw error;
    }
  });
}

export function assert(
  condition: unknown,
  message?: string
): asserts condition {
  if (!condition) throw new Error(message);
}

export async function getAddressFromCip30Wallet(
  cip30Wallet: helios.Cip30Wallet
): Promise<AddressBech32> {
  const walletHelper = new helios.WalletHelper(cip30Wallet);
  const heliosAddress = await walletHelper.baseAddress;
  return heliosAddress.toBech32();
}

export async function getStakeAddressFromCip30Wallet(
  cip30Wallet: helios.Cip30Wallet
): Promise<AddressBech32> {
  const walletHelper = new helios.WalletHelper(cip30Wallet);
  const baseAddressH = await walletHelper.baseAddress;
  const stakeAddressH = helios.StakeAddress.fromAddress(baseAddressH);
  const stakeAddress = stakeAddressH.toBech32();
  return stakeAddress;
}
