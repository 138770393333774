export default function IconInfo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#4073F7" />
      <path
        d="M12.0002 5.3335C8.32016 5.3335 5.3335 8.32016 5.3335 12.0002C5.3335 15.6802 8.32016 18.6668 12.0002 18.6668C15.6802 18.6668 18.6668 15.6802 18.6668 12.0002C18.6668 8.32016 15.6802 5.3335 12.0002 5.3335ZM12.6668 15.3335H11.3335V11.3335H12.6668V15.3335ZM12.6668 10.0002H11.3335V8.66683H12.6668V10.0002Z"
        fill="white"
      />
    </svg>
  );
}
