import * as helios from "@hyperionbt/helios";
import * as Sentry from "@sentry/nextjs";
import { assert$DisplayableError } from "kreate-common/modules/displayable-error";
import React from "react";
import useSWR from "swr";

import { useDebounce } from "../common-hooks/useDebounce";

import { ApiContext as CardanoWalletApiContext } from "./contexts/ApiContext";
import {
  fetcher$LoadWalletName,
  fetcher$SaveWalletName,
  getResourceKey$LoadWalletName,
} from "./fetcher";
import { useBasicWalletInfo } from "./hooks/useBasicWalletInfo";
import { useCardanoWalletApi } from "./hooks/useCardanoWalletApi";
import { CardanoWalletApi, WalletName } from "./types";
import { enableWallet, isInitialApi, isWalletInstalled } from "./utils";

function useApi(): CardanoWalletApi {
  const swr_walletName = useSWR(
    getResourceKey$LoadWalletName(),
    async () => await fetcher$LoadWalletName()
  );
  const walletName = swr_walletName.data;

  const swr_cip30Handle = useSWR(
    walletName
      ? ["e953760a-2cfa-4477-a87a-8cdcebbc3a23", walletName]
      : undefined,
    async () => {
      if (!walletName) return undefined;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const initialApi = (window as any)?.cardano?.[walletName];
      assert$DisplayableError(
        isInitialApi(initialApi),
        `Wallet not installed (${walletName})`
      );

      const enabled = await initialApi.isEnabled();
      if (!enabled) return undefined;

      return await initialApi.enable();
    },
    {
      onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
        if (retryCount >= 10) return;
        setTimeout(() => revalidate({ retryCount }), 250);
      },
    }
  );
  const cip30Handle = swr_cip30Handle.data;

  const {
    cip30Wallet,
    walletHelper,
    baseAddress,
    balance,
    error: error_useBasicWalletInfo,
  } = useBasicWalletInfo({
    walletName,
    cip30Handle,
    refreshInterval: 5000,
  });

  const [connectingState, setConnectingState] = React.useState<{
    walletName: WalletName;
    abortController: AbortController;
  }>();

  const connect = async (
    walletName: WalletName
  ): Promise<helios.Cip30Handle> => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    setConnectingState({ walletName, abortController });

    try {
      const cip30Handle = await enableWallet(walletName);
      signal.throwIfAborted();
      await fetcher$SaveWalletName(walletName);
      void swr_walletName.mutate();
      Sentry.addBreadcrumb({
        message: `[modules/cardano-wallet-v2] Connected to "${walletName}"`,
      });
      return cip30Handle;
    } finally {
      setConnectingState(undefined);
    }
  };

  const disconnect = async () => {
    await fetcher$SaveWalletName(undefined);
    await swr_walletName.mutate();
  };

  if (connectingState) {
    return {
      type: "connecting",
      walletName: connectingState.walletName,
      abort: () => connectingState.abortController.abort(),
    };
  }

  if (!walletName) {
    return {
      type: "disconnected",
      connect,
      isWalletInstalled,
    };
  }

  const ready =
    !error_useBasicWalletInfo &&
    balance != null &&
    baseAddress != null &&
    cip30Handle != null &&
    cip30Wallet != null &&
    walletHelper != null;

  if (!ready) {
    return {
      type: "loading",
      walletName,
      address: baseAddress?.toBech32(),
      lovelaceAmount: balance?.lovelace,
      error:
        error_useBasicWalletInfo ||
        swr_walletName.error ||
        swr_cip30Handle.error,
      disconnect,
    };
  }

  return {
    type: "ready",
    walletName,
    cip30Handle,
    cip30Wallet,
    walletHelper,
    baseAddress,
    address: baseAddress.toBech32(),
    lovelaceAmount: balance.lovelace,
    disconnect,
  };
}

export function CardanoWalletApiProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const api = useApi();

  // auto disconnect when the condition below holds true for more than 1 second
  const [bad] = useDebounce(
    api.type === "loading" && !!api.error, //
    { delay: 1000 }
  );

  React.useEffect(() => {
    if (bad && api.type === "loading") {
      api.disconnect();
    }
  }, [api, bad]);

  return (
    <CardanoWalletApiContext.Provider value={api}>
      {children}
    </CardanoWalletApiContext.Provider>
  );
}

export { useCardanoWalletApi };
