import Script from "next/script";
import React from "react";

type Props = {
  clientId: string;
  onTokenReceived?: (event: CredentialResponse) => void;
  onReady?: () => void;
};

type CredentialResponse = google.accounts.id.CredentialResponse;

export default class ClientLoader extends React.Component<Props> {
  private triggerButton?: HTMLElement;

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Script
        id="8a9bde6d-f62a-47bf-8be0-2a7757ff8d09"
        src="https://accounts.google.com/gsi/client"
        strategy="afterInteractive"
        onLoad={() => {
          const promptContainer = document.createElement("div");
          promptContainer.id = "cd421620-0915-409c-a129-7c52f4db9346";
          Object.assign(promptContainer.style, {
            display: "block",
            position: "fixed",
            right: "48px",
            top: "98px",
            // TODO: ideally, we should eliminate the following magic number
            // by using a .scss file. Let's keep this for now for simplicity.
            zIndex: 100,
          });
          document.body.appendChild(promptContainer);

          window.google.accounts.id.initialize({
            client_id: this.props.clientId,
            prompt_parent_id: promptContainer.id,
            ux_mode: "popup",
            callback: (event) => {
              this.props.onTokenReceived?.(event);
            },
          });

          const hiddenDiv = document.createElement("div");
          hiddenDiv.style.display = "none";
          document.body.appendChild(hiddenDiv);

          window.google.accounts.id.renderButton(
            hiddenDiv,
            // https://stackoverflow.com/q/76770357/2742305
            // https://developers.google.com/identity/gsi/web/reference/js-reference#width
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { type: "icon", width: 200 as any }
          );

          const buttonElement = hiddenDiv.querySelector("div[role=button]");
          if (buttonElement instanceof HTMLElement) {
            this.triggerButton = buttonElement;
          }

          this.props.onReady?.();
        }}
      />
    );
  }

  triggerPrompt() {
    window.google.accounts.id.prompt();
  }

  triggerAuthentication() {
    this.triggerButton?.click();
  }
}
