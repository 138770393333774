import { setCookie } from "cookies-next";
import { getCookie } from "cookies-next";
import { ResourceKey } from "kreate-server/types";

import { WalletName } from "./types";

export async function fetcher$LoadWalletName() {
  const text = getCookie("wallet-name");
  if (typeof text !== "string") return undefined;
  try {
    return text ? WalletName.parse(text) : undefined;
  } catch {
    return undefined;
  }
}

export async function fetcher$SaveWalletName(
  walletName: WalletName | undefined
) {
  setCookie("wallet-name", walletName || "");
}

export function getResourceKey$LoadWalletName(): ResourceKey {
  return ["448f77f9-bab4-498c-b8cf-df7226b48248"];
}
