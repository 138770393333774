import { Slot as RxSlot } from "@radix-ui/react-slot";
import cx from "clsx";
import * as React from "react";

import styles from "./index.module.scss";

type StyleKeys =
  | "alignContent"
  | "alignItems"
  | "alignSelf"
  | "alignTracks"
  | "boxSizing"
  | "columnGap"
  | "display"
  | "flex"
  | "flexBasis"
  | "flexDirection"
  | "flexFlow"
  | "flexGrow"
  | "flexShrink"
  | "flexWrap"
  | "gap"
  | "height"
  | "justifyContent"
  | "justifyItems"
  | "justifySelf"
  | "justifyTracks"
  | "maxHeight"
  | "maxWidth"
  | "minHeight"
  | "minWidth"
  | "padding"
  | "paddingBlock"
  | "paddingBlockEnd"
  | "paddingBlockStart"
  | "paddingBottom"
  | "paddingInline"
  | "paddingInlineEnd"
  | "paddingInlineStart"
  | "paddingLeft"
  | "paddingRight"
  | "paddingTop"
  | "rowGap"
  | "width";

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
  asChild?: boolean;
};

type StyleProps = Pick<React.CSSProperties, StyleKeys>;

type ForwardedProps = Omit<
  React.HTMLAttributes<HTMLDivElement & HTMLSpanElement>,
  keyof OwnProps | keyof StyleProps
>;

type Props = OwnProps & StyleProps & ForwardedProps;

function partitionProps(
  props: StyleProps & ForwardedProps
): [StyleProps, ForwardedProps] {
  const styleProps: Record<string, unknown> = {};
  const forwardedProps: Record<string, unknown> = {};

  for (const key in props) {
    if (key.includes("-")) {
      forwardedProps[key] = (props as Record<string, unknown>)[key];
    } else if (
      [
        "align",
        "boxSizing",
        "columnGap",
        "display",
        "flex",
        "gap",
        "height",
        "justify",
        "max",
        "min",
        "padding",
        "rowGap",
        "width",
      ].some((item) => key.startsWith(item))
    ) {
      styleProps[key] = (props as Record<string, unknown>)[key];
    } else {
      forwardedProps[key] = (props as Record<string, unknown>)[key];
    }
  }

  return [styleProps, forwardedProps];
}

export const Row = React.forwardRef<HTMLDivElement, Props>(function Row(
  { className, style, content, children = content, asChild, ...others }: Props,
  ref
) {
  const Component = asChild ? RxSlot : "div";
  const [styleProps, forwardedProps] = partitionProps(others);
  return (
    <Component
      className={cx(styles.row, className)}
      style={{ ...styleProps, ...style }}
      ref={ref}
      {...forwardedProps}
    >
      {children}
    </Component>
  );
});

export const Col = React.forwardRef<HTMLDivElement, Props>(function Col(
  { className, style, content, children = content, asChild, ...others }: Props,
  ref
) {
  const Component = asChild ? RxSlot : "div";
  const [styleProps, forwardedProps] = partitionProps(others);
  return (
    <Component
      className={cx(styles.col, className)}
      style={{ ...styleProps, ...style }}
      ref={ref}
      {...forwardedProps}
    >
      {children}
    </Component>
  );
});

export const Cell = React.forwardRef<HTMLDivElement, Props>(function Cell(
  { className, style, content, children = content, asChild, ...others }: Props,
  ref
) {
  const Component = asChild ? RxSlot : "div";
  const [styleProps, forwardedProps] = partitionProps(others);
  return (
    <Component
      className={cx(styles.cell, className)}
      style={{ ...styleProps, ...style }}
      ref={ref}
      {...forwardedProps}
    >
      {children}
    </Component>
  );
});
