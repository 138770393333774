import React from "react";

import styles from "./index.module.scss";

export const THEME = {
  default: styles.themeDefault,
  classicLight: styles.themeClassicLight,
  classicDark: styles.themeClassicDark,
  chocolate: styles.themeChocolate,
};

export type Theme = keyof typeof THEME;

export function isTheme(value: unknown): value is Theme {
  return typeof value === "string" && Object.keys(THEME).includes(value);
}

export const ThemeContext = React.createContext<Theme>("default");
