import React from "react";

type ModalConfig = {
  container: HTMLElement | null;
};

export const ModalConfig$Context = React.createContext<ModalConfig>({
  container: null,
});

export function useModalConfig() {
  return React.useContext(ModalConfig$Context);
}
