export default function IconWarning() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#EC5929" />
      <path
        d="M4.6665 18.0002H19.3332L11.9998 5.3335L4.6665 18.0002ZM12.6665 16.0002H11.3332V14.6668H12.6665V16.0002ZM12.6665 13.3335H11.3332V10.6668H12.6665V13.3335Z"
        fill="white"
      />
    </svg>
  );
}
