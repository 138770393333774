import * as Toast from "@radix-ui/react-toast";
import cx from "clsx";
import { DisplayableError } from "kreate-common/modules/displayable-error";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";
import { MdClose } from "react-icons/md";

import IconDanger from "./icons/IconDanger";
import IconInfo from "./icons/IconInfo";
import IconSuccess from "./icons/IconSuccess";
import IconWarning from "./icons/IconWarning";
import styles from "./index.module.scss";

const DEFAULT_DURATION = 5000;
const DEFAULT_DURATION_FOR_ERROR = 60000;

type ToastInterface = {
  showError: (params: ToastData$Error) => void;
  showMessage: (params: ToastData$Message) => void;
};

const defaultValue$ToastContext: ToastInterface = {
  showError: (params) => alert(params.error),
  showMessage: (params) =>
    globalThis.alert(params.title + "\n\n" + params.description),
};

export const ToastContext = React.createContext<ToastInterface>(
  defaultValue$ToastContext
);

export function useToast() {
  return React.useContext(ToastContext);
}

// https://getbootstrap.com/docs/5.3/customize/color/
const COLOR_TO_CLASS_NAME = {
  info: styles.colorInfo,
  warning: styles.colorWarning,
  success: styles.colorSuccess,
  danger: styles.colorDanger,
};

type ToastData$Error = { error: unknown };

type ToastData$Message = {
  color?: keyof typeof COLOR_TO_CLASS_NAME;
  title?: string;
  description?: React.ReactNode;
  actions?: {
    title: string;
    onClick?: () => void;
  }[];
  duration?: number;
};

type ToastData =
  | { type: "error"; error: DisplayableError }
  | ({ type: "message" } & ToastData$Message);

type Props = {
  children?: React.ReactNode;
};

const icons: Record<keyof typeof COLOR_TO_CLASS_NAME, React.ReactNode> = {
  info: <IconInfo />,
  warning: <IconWarning />,
  success: <IconSuccess />,
  danger: <IconDanger />,
};

export function ToastContextProvider({ children }: Props) {
  const [toastItems, setToastItems] = React.useState<
    Record<number, ToastData | undefined>
  >({});

  const addToastItem = (toastItem: ToastData) => {
    const id = Date.now();
    setToastItems((obj) => ({ ...obj, [id]: toastItem }));
  };

  const showError = ({ error }: ToastData$Error) => {
    const displayableError = DisplayableError.from(error);
    addToastItem({ type: "error", error: displayableError });
  };

  const showMessage = (params: ToastData$Message) => {
    addToastItem({ type: "message", ...params });
  };

  return (
    <ToastContext.Provider value={{ showError, showMessage }}>
      <Toast.Provider>
        {children}
        <Toast.Viewport className={styles.toastViewport} />
        {Object.entries(toastItems).map(([id, toastItem]) => {
          if (toastItem?.type === "error") {
            const { title, description } = toastItem.error;
            return (
              <Toast.Root
                key={id}
                className={cx(styles.toastRoot, styles.error)}
                duration={DEFAULT_DURATION_FOR_ERROR}
              >
                <Toast.Title className={styles.toastTitle}>{title}</Toast.Title>
                <Toast.Description className={styles.toastDescription}>
                  {description}
                </Toast.Description>
                <Toast.Close className={styles.toastClose}>
                  <MdClose size="24px" color="#d02f2d" />
                </Toast.Close>
              </Toast.Root>
            );
          } else if (toastItem?.type === "message") {
            return (
              <Toast.Root
                key={id}
                className={cx(
                  styles.toastRoot,
                  toastItem.color ? COLOR_TO_CLASS_NAME[toastItem.color] : null
                )}
                duration={toastItem.duration || DEFAULT_DURATION}
              >
                <Flex.Row gap="20px">
                  <Flex.Row>
                    {toastItem.color ? icons[toastItem.color] : null}
                  </Flex.Row>
                  <Flex.Col minWidth="100px" justifyContent="center">
                    {toastItem.title ? (
                      <Toast.Title className={styles.toastTitle}>
                        {toastItem.title}
                      </Toast.Title>
                    ) : null}
                    {toastItem.description ? (
                      <Toast.Description className={styles.toastDescription}>
                        {toastItem.description}
                      </Toast.Description>
                    ) : null}
                  </Flex.Col>
                </Flex.Row>
                <Toast.Close className={styles.toastClose}>
                  <MdClose size="24px" />
                </Toast.Close>
                {toastItem.actions && toastItem.actions.length > 0 ? (
                  <Flex.Row
                    justifyContent="end"
                    gap="4px"
                    style={{ marginTop: "8px" }}
                  >
                    {toastItem.actions?.map(({ title, onClick }, index) => (
                      <Toast.Action
                        className={styles.button}
                        key={index}
                        onClick={onClick}
                        altText={title}
                      >
                        {title}
                      </Toast.Action>
                    ))}
                  </Flex.Row>
                ) : null}
              </Toast.Root>
            );
          } else {
            return null;
          }
        })}
      </Toast.Provider>
    </ToastContext.Provider>
  );
}
