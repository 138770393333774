import { fromJson } from "kreate-common/modules/json-utils";

import { ResourceKey } from "../../types";

import { Result } from "./typing";

export async function httpGet(url: string) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const text = await response.text();
  const data = fromJson(text);
  const result = Result.parse(data);
  return result;
}

export function getResourceKey(): ResourceKey {
  return ["ef5383de-6d5d-46ab-8f28-f316b6a136ca"];
}

export const httpGet$LoginStatus = httpGet;
export const getResourceKey$LoginStatus = getResourceKey;
