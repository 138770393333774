import cx from "clsx";
import * as React from "react";

import styles from "./index.module.scss";

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  margin?: React.CSSProperties["margin"];
  borderStyle?: React.CSSProperties["borderLeftStyle"] &
    React.CSSProperties["borderTopStyle"];
};

type ForwardedProps = Omit<React.HTMLAttributes<HTMLHRElement>, keyof OwnProps>;

type Props = OwnProps & ForwardedProps;

export function VerticalDivider({
  className,
  style,
  margin,
  borderStyle = "solid",
  ...others
}: Props) {
  return (
    <hr
      className={cx(styles.verticalDivider, className)}
      style={{ borderLeftStyle: borderStyle, margin, ...style }}
      {...others}
    />
  );
}

export function HorizontalDivider({
  className,
  style,
  margin,
  borderStyle,
  ...others
}: Props) {
  return (
    <hr
      className={cx(styles.verticalDivider, className)}
      style={{ borderTopStyle: borderStyle, margin, ...style }}
      {...others}
    />
  );
}
