var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6879233ddf04a4b9bb2f3202770d6484797809d5"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { CLIENT_ENV } from "kreate-env/client";

// Enable Sentry conditionally
// https://docs.sentry.io/platforms/node/configuration/options/#enabled
if (CLIENT_ENV.SENTRY_DSN) {
  Sentry.init({
    dsn: CLIENT_ENV.SENTRY_DSN,
    tracesSampleRate: 1.0,
    // https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
    // > Any data beyond this depth will be trimmed and marked using its type
    // instead ([Object] or [Array]), without walking the tree any further.
    // By default, walking is performed three levels deep.
    normalizeDepth: 5,
  });
}
