import * as helios from "@hyperionbt/helios";
import { z } from "zod";

export type AddressBech32 = string;

export const WalletName = z.enum([
  "nami",
  "cardwallet",
  "nufi",
  "eternl",
  "flint",
  "gerowallet",
  "typhoncip30",
  "vespr",
  "lace",
]);

export type WalletName = z.infer<typeof WalletName>;

export type CardanoWalletApi =
  | {
      type: "ready";
      walletName: WalletName;
      cip30Handle: helios.Cip30Handle;
      cip30Wallet: helios.Cip30Wallet;
      walletHelper: helios.WalletHelper;
      baseAddress: helios.Address;
      address: AddressBech32;
      lovelaceAmount: bigint;
      disconnect: () => void;
    }
  | {
      type: "loading";
      walletName: WalletName;
      address: AddressBech32 | undefined;
      lovelaceAmount: bigint | undefined;
      error: unknown | undefined;
      disconnect: () => void;
    }
  | {
      type: "connecting";
      walletName: WalletName;
      abort: () => void;
    }
  | {
      type: "disconnected";
      isWalletInstalled: (walletName: WalletName) => boolean;
      connect: (walletName: WalletName) => Promise<helios.Cip30Handle>;
    };
