import { Analytics } from "@vercel/analytics/react";
import useBodyClasses from "kreate-common/modules/common-hooks/useBodyClasses";
import { ModalPromisesProvider } from "kreate-common/modules/kreate-ui/components/Modal/hoc/withModalPromises";
import { ModalConfig$Context } from "kreate-common/modules/kreate-ui/components/Modal/hooks/useModalConfig";
import ThemeProvider from "kreate-common/modules/kreate-ui/components/ThemeProvider";
import type { AppProps } from "next/app";
import { Inter, Playfair_Display } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import "normalize.css";
import "./nprogress.css";

import styles from "./_app.module.scss";

import { CardanoWalletApiProvider } from "@/modules/cardano-wallet";
import { useRouteChangeIndicator } from "@/modules/common-hooks/useRouteChangeIndicator";
import { shamelesslyRevalidateEverything } from "@/modules/common-utils/shamelesslyRevalidateEverything";
import { GoogleAuthenticationProvider } from "@/modules/google-authentication";
import { ToastContextProvider } from "@/modules/kreate-contexts/ToastContext";
// Without `display: "swap"`, if the primary font does not load within
// 100ms, the fallback font will be displayed.
// https://nextjs.org/docs/basic-features/font-optimization#choosing-font-display

const fontInter = Inter({
  subsets: ["latin", "vietnamese"],
  variable: "--font-family-inter",
  display: "swap",
});

const fontPlayfairDisplay = Playfair_Display({
  subsets: ["latin", "vietnamese"],
  style: ["italic", "normal"],
  variable: "--font-family-playfair-display",
  display: "swap",
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const redirectUrl$OnAuthenticationSuccess = router.query.redirectUrl;

  useRouteChangeIndicator();

  useBodyClasses([
    fontPlayfairDisplay.variable,
    fontInter.variable,
    styles.body,
  ]);

  const [modalContainerElement, setModalContainerElement] =
    React.useState<HTMLDivElement | null>(null);

  return (
    <>
      <Head>
        <meta
          key="14261f2d-df49-4570-a1dc-ced918ff8a6c"
          name="viewport"
          content="width=device-width,initial-scale=0.5"
        />
        <link
          key="0d09c79a-1b73-4100-b2f2-de3acab82bb8"
          rel="icon"
          type="image/png"
          href="/images/favicon.png"
        />
      </Head>
      <ThemeProvider theme="default">
        <ModalConfig$Context.Provider
          value={{ container: modalContainerElement }}
        >
          <GoogleAuthenticationProvider
            onSuccess={async ({ userId }) => {
              console.log(`logged in as ${userId}`);
              await shamelesslyRevalidateEverything();
              await router.push(
                typeof redirectUrl$OnAuthenticationSuccess === "string" &&
                  isSafeUrl(redirectUrl$OnAuthenticationSuccess)
                  ? redirectUrl$OnAuthenticationSuccess
                  : "/"
              );
            }}
            onFailure={(reason) => {
              // TODO: display a toast properly
              alert(reason.message);
            }}
          >
            <CardanoWalletApiProvider>
              <ToastContextProvider>
                <ModalPromisesProvider>
                  <Component {...pageProps} />
                </ModalPromisesProvider>
              </ToastContextProvider>
            </CardanoWalletApiProvider>
          </GoogleAuthenticationProvider>
        </ModalConfig$Context.Provider>
      </ThemeProvider>
      <div
        ref={setModalContainerElement}
        className={styles.modalContainer}
      ></div>
      <Analytics />
    </>
  );
}

function isSafeUrl(url: string) {
  return url.startsWith("/") && !url.startsWith("//");
}
