import { CardanoNetwork } from "kreate-common/modules/business-types";
import { z } from "zod";

export const ClientEnv = z.object({
  CLIENT_ID: z.string().endsWith(".apps.googleusercontent.com"),
  CLOUDFRONT_HOST: z.string().endsWith(".cloudfront.net"),
  KREATE_ORIGIN: z.string().regex(/^https?:\/\/.*[^/]$/),
  SENTRY_DSN: z
    .string()
    .regex(/^https?:\/\/.*[^/]$/)
    .optional(),
  CARDANO_NETWORK: CardanoNetwork,
  KOLLECTOR_MINT_FEE_PERCENTAGE: z.number(),
  MIN_PROTOCOL_FEE: z.number(), // lovelace
  ADDRESS_MODERATOR: z.string(),
  SCRIPT_STAKE_KEY_HASH: z.string(),
});

export type ClientEnv = z.infer<typeof ClientEnv>;

export const CLIENT_ENV = ClientEnv.parse({
  CLIENT_ID: process.env.NEXT_PUBLIC_CLIENT_ID,
  CLOUDFRONT_HOST: process.env.NEXT_PUBLIC_CLOUDFRONT_HOST,
  CARDANO_NETWORK: CardanoNetwork.parse(
    process.env.NEXT_PUBLIC_CARDANO_NETWORK
  ),
  KREATE_ORIGIN: process.env.NEXT_PUBLIC_KREATE_ORIGIN,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  KOLLECTOR_MINT_FEE_PERCENTAGE: parseInt(
    process.env.NEXT_PUBLIC_KOLLECTOR_MINT_FEE_PERCENTAGE || "2"
  ),
  MIN_PROTOCOL_FEE: parseInt(
    process.env.NEXT_PUBLIC_MIN_PROTOCOL_FEE || "2000000"
  ),
  ADDRESS_MODERATOR: process.env.NEXT_PUBLIC_ADDRESS_MODERATOR,
  SCRIPT_STAKE_KEY_HASH: process.env.NEXT_PUBLIC_SCRIPT_STAKE_KEY_HASH,
});

// @kien-kreate: yes, utils or constants that are highly associated
// with env are exported here as well. Env is just some raw strings to
// describe a config. Since we cannot put a function in env, we can put
// sufficient info, then write a function here based on that info.
export function blobIdToUrl(blobId: string) {
  return `https://${CLIENT_ENV.CLOUDFRONT_HOST}/blobs/${blobId}`;
}
