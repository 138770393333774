import { FileHandle, UserId } from "kreate-common/modules/business-types";
import { z } from "zod";

export const Result = z.union([
  z.object({
    loggedIn: z.literal(true),
    userId: UserId,
    avatar: z.optional(FileHandle),
    displayName: z.string(),
    isAgent: z.boolean(),
    isVerified: z.boolean(),
    flagWarning: z.boolean(),
    flagSuspended: z.boolean(),
    customUnsortedCollectionName: z.string().nullish(),
  }),
  z.object({
    loggedIn: z.literal(false),
  }),
]);

export type Result = z.infer<typeof Result>;

export const Token = z.object({
  userId: z.string(),
});

export type Token = z.infer<typeof Token>;

export type LoginStatus$Result = Result;
export const LoginStatus$Result = Result;
