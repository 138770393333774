import React from "react";

export type RenderFunction<T> = (params: {
  resolve: (value: T) => void;
  reject: (reason: unknown) => void;
}) => React.ReactNode;

export type ShowModalFunction = <T>(render: RenderFunction<T>) => Promise<T>;

export type ModalPromises = {
  showModal: ShowModalFunction;
};

const defaultModalPromises: ModalPromises = {
  showModal: () => {
    throw new Error("context not injected");
  },
};

const Context = React.createContext<ModalPromises>(defaultModalPromises);

export function useModalPromises() {
  return React.useContext(Context);
}

let nextId = 0;

export function ModalPromisesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [nodes, setNodes] = React.useState<Record<number, React.ReactNode>>({});

  const setNode = (id: number, node: React.ReactNode) => {
    setNodes((obj) => ({ ...obj, [id]: node }));
  };

  const showModal: ShowModalFunction = function <T>(render: RenderFunction<T>) {
    return new Promise<T>((resolve, reject) => {
      const id = nextId++;
      const node = render({
        resolve: (value) => {
          setNode(id, null);
          resolve(value);
        },
        reject: (reason) => {
          setNode(id, null);
          reject(reason);
        },
      });
      setNode(id, node);
    });
  };

  return (
    <Context.Provider value={{ showModal }}>
      {children}
      {Object.entries(nodes).map(([id, node]) => (
        <React.Fragment key={id}>{node}</React.Fragment>
      ))}
    </Context.Provider>
  );
}
