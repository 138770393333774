import { Slot as RxSlot } from "@radix-ui/react-slot";
import cx from "clsx";
import React from "react";

import useBodyClasses from "../../../common-hooks/useBodyClasses";

import { THEME, ThemeContext } from "./config";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
  theme: keyof typeof THEME | null | undefined;
  asChild?: boolean;
  shouldApplyClassNameToBody?: boolean;
};

export default function ThemeProvider({
  className,
  style,
  content,
  children = content,
  theme,
  asChild,
  shouldApplyClassNameToBody = true,
}: Props) {
  const Component = asChild ? RxSlot : "div";
  const inputTheme = React.useContext(ThemeContext);
  const outputTheme = theme || inputTheme;
  useBodyClasses(shouldApplyClassNameToBody ? [THEME[outputTheme]] : []);

  return (
    <ThemeContext.Provider value={outputTheme}>
      <Component
        className={cx(styles.container, className, THEME[outputTheme])}
        style={style}
      >
        {children}
      </Component>
    </ThemeContext.Provider>
  );
}
