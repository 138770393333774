import { UserId } from "kreate-common/modules/business-types";
import { DisplayableError } from "kreate-common/modules/displayable-error";
import { CLIENT_ENV } from "kreate-env/client";
import { httpPost$AuthenticateByGoogle } from "kreate-server/commands/AuthenticateByGoogle/fetcher";
import React from "react";

import { Api, ApiContext } from "../contexts/ApiContext";

import ClientLoader from "./ClientLoader";

import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";

type SuccessEvent = { userId: UserId };

type Props = {
  children?: React.ReactNode;
  onSuccess?: (event: SuccessEvent) => void;
  onFailure?: (reason: DisplayableError) => void;
};

export default function ApiProvider({ children, onSuccess, onFailure }: Props) {
  const [api, setApi] = React.useState<Api>();
  const clientLoaderRef = React.useRef<ClientLoader>(null);
  const loginStatus = useLoginStatus();

  const handleTokenReceived = async ({
    credential,
  }: google.accounts.id.CredentialResponse) => {
    try {
      const result$AuthenticateByGoogle = await httpPost$AuthenticateByGoogle(
        "/api/v1/auth/authenticate-by-google",
        { credential }
      );
      onSuccess?.({ userId: result$AuthenticateByGoogle.userId });
    } catch (error) {
      onFailure?.(
        DisplayableError.from(error, "Failed to authenticate with Google")
      );
    }
  };

  const canPrompt = loginStatus?.confirmed && !loginStatus.loggedIn && !!api;

  React.useEffect(() => {
    // show One Tap overlay
    if (!canPrompt) return;
    clientLoaderRef?.current?.triggerPrompt();
  }, [canPrompt]);

  return (
    <>
      <ClientLoader
        ref={clientLoaderRef}
        clientId={CLIENT_ENV.CLIENT_ID}
        onTokenReceived={handleTokenReceived}
        onReady={() => {
          setApi({
            triggerAuthentication: () =>
              clientLoaderRef?.current?.triggerAuthentication(),
          });
        }}
      />
      <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
    </>
  );
}
