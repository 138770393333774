import * as helios from "@hyperionbt/helios";
import React from "react";
import useSWR from "swr";

import { WalletName } from "../types";

type Params = {
  walletName: WalletName | undefined;
  cip30Handle: helios.Cip30Handle | undefined;
  refreshInterval: number | undefined;
};

type Result = {
  cip30Wallet: helios.Cip30Wallet | undefined;
  walletHelper: helios.WalletHelper | undefined;
  baseAddress: helios.Address | undefined;
  balance: helios.Value | undefined;
  error: unknown | undefined;
};

export function useBasicWalletInfo({
  walletName,
  cip30Handle,
  refreshInterval,
}: Params): Result {
  const cip30Wallet = React.useMemo(
    () => (cip30Handle ? new helios.Cip30Wallet(cip30Handle) : undefined),
    [cip30Handle]
  );

  const walletHelper = React.useMemo(
    () => (cip30Wallet ? new helios.WalletHelper(cip30Wallet) : undefined),
    [cip30Wallet]
  );

  const swr = {
    baseAddress: useSWR(
      [
        "e8957fa6-da33-4fd2-b041-c08bfc95cf02",
        walletName,
        !!cip30Handle,
        !!cip30Wallet,
        !!walletHelper,
      ],
      async () => await walletHelper?.baseAddress,
      { refreshInterval }
    ),
    balance: useSWR(
      [
        "582f08df-c3b3-4922-aeb2-805a8725acff",
        walletName,
        !!cip30Handle,
        !!cip30Wallet,
        !!walletHelper,
      ],
      async () => await walletHelper?.calcBalance(),
      { refreshInterval }
    ),
  };

  return {
    cip30Wallet,
    walletHelper,
    baseAddress: swr.baseAddress.data,
    balance: swr.balance.data,
    error: swr.baseAddress.error || swr.balance.error,
  };
}
