export default function IconDanger() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#D02F2D" />
      <path
        d="M16.6668 8.2735L15.7268 7.3335L12.0002 11.0602L8.2735 7.3335L7.3335 8.2735L11.0602 12.0002L7.3335 15.7268L8.2735 16.6668L12.0002 12.9402L15.7268 16.6668L16.6668 15.7268L12.9402 12.0002L16.6668 8.2735Z"
        fill="white"
      />
    </svg>
  );
}
